import {
  __spreadProps,
  __spreadValues
} from "../chunk-LMOQIXV4.js";
import { parse } from "graphql";
import { QueryStore } from "./query";
const MAX_QUERY_SIZE = 1e5;
class HistoryStore {
  constructor(storage, maxHistoryLength) {
    this.storage = storage;
    this.maxHistoryLength = maxHistoryLength;
    this.updateHistory = ({
      query,
      variables,
      headers,
      operationName
    }) => {
      if (!this.shouldSaveQuery(
        query,
        variables,
        headers,
        this.history.fetchRecent()
      ))
        return;
      this.history.push({
        query,
        variables,
        headers,
        operationName
      });
      const historyQueries = this.history.items, favoriteQueries = this.favorite.items;
      this.queries = historyQueries.concat(favoriteQueries);
    };
    this.deleteHistory = ({ query, variables, headers, operationName, favorite }, clearFavorites = !1) => {
      function deleteFromStore(store) {
        const found = store.items.find(
          (x) => x.query === query && x.variables === variables && x.headers === headers && x.operationName === operationName
        );
        found && store.delete(found);
      }
      (favorite || clearFavorites) && deleteFromStore(this.favorite), (!favorite || clearFavorites) && deleteFromStore(this.history), this.queries = [...this.history.items, ...this.favorite.items];
    };
    this.history = new QueryStore(
      "queries",
      this.storage,
      this.maxHistoryLength
    ), this.favorite = new QueryStore("favorites", this.storage, null), this.queries = [...this.history.fetchAll(), ...this.favorite.fetchAll()];
  }
  shouldSaveQuery(query, variables, headers, lastQuerySaved) {
    if (!query)
      return !1;
    try {
      parse(query);
    } catch (e) {
      return !1;
    }
    return query.length > MAX_QUERY_SIZE ? !1 : lastQuerySaved ? !(JSON.stringify(query) === JSON.stringify(lastQuerySaved.query) && (JSON.stringify(variables) === JSON.stringify(lastQuerySaved.variables) && (JSON.stringify(headers) === JSON.stringify(lastQuerySaved.headers) || headers && !lastQuerySaved.headers) || variables && !lastQuerySaved.variables)) : !0;
  }
  toggleFavorite({
    query,
    variables,
    headers,
    operationName,
    label,
    favorite
  }) {
    const item = {
      query,
      variables,
      headers,
      operationName,
      label
    };
    favorite ? (item.favorite = !1, this.favorite.delete(item), this.history.push(item)) : (item.favorite = !0, this.favorite.push(item), this.history.delete(item)), this.queries = [...this.history.items, ...this.favorite.items];
  }
  editLabel({
    query,
    variables,
    headers,
    operationName,
    label,
    favorite
  }, index) {
    const item = {
      query,
      variables,
      headers,
      operationName,
      label
    };
    favorite ? this.favorite.edit(__spreadProps(__spreadValues({}, item), { favorite }), index) : this.history.edit(item, index), this.queries = [...this.history.items, ...this.favorite.items];
  }
}
export {
  HistoryStore
};
