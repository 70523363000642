import "../chunk-LMOQIXV4.js";
import {
  createMultipartFetcher,
  createSimpleFetcher,
  isSubscriptionWithName,
  getWsFetcher
} from "./lib";
function createGraphiQLFetcher(options) {
  const httpFetch = options.fetch || typeof window != "undefined" && window.fetch;
  if (!httpFetch)
    throw new Error("No valid fetcher implementation available");
  options.enableIncrementalDelivery = options.enableIncrementalDelivery !== !1;
  const simpleFetcher = createSimpleFetcher(options, httpFetch), httpFetcher = options.enableIncrementalDelivery ? createMultipartFetcher(options, httpFetch) : simpleFetcher;
  return async (graphQLParams, fetcherOpts) => {
    if (graphQLParams.operationName === "IntrospectionQuery")
      return (options.schemaFetcher || simpleFetcher)(
        graphQLParams,
        fetcherOpts
      );
    if (fetcherOpts != null && fetcherOpts.documentAST ? isSubscriptionWithName(
      fetcherOpts.documentAST,
      graphQLParams.operationName || void 0
    ) : !1) {
      const wsFetcher = await getWsFetcher(options, fetcherOpts);
      if (!wsFetcher)
        throw new Error(
          `Your GraphiQL createFetcher is not properly configured for websocket subscriptions yet. ${options.subscriptionUrl ? `Provided URL ${options.subscriptionUrl} failed` : "Please provide subscriptionUrl, wsClient or legacyClient option first."}`
        );
      return wsFetcher(graphQLParams);
    }
    return httpFetcher(graphQLParams, fetcherOpts);
  };
}
export {
  createGraphiQLFetcher
};
