import {
  __spreadProps,
  __spreadValues
} from "../chunk-LMOQIXV4.js";
function stringify(obj) {
  return JSON.stringify(obj, null, 2);
}
function formatSingleError(error) {
  return __spreadProps(__spreadValues({}, error), {
    // Raise these details even if they're non-enumerable
    message: error.message,
    stack: error.stack
  });
}
function handleSingleError(error) {
  return error instanceof Error ? formatSingleError(error) : error;
}
function formatError(error) {
  return Array.isArray(error) ? stringify({
    errors: error.map((e) => handleSingleError(e))
  }) : stringify({ errors: [handleSingleError(error)] });
}
function formatResult(result) {
  return stringify(result);
}
export {
  formatError,
  formatResult
};
