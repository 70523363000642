import "../chunk-LMOQIXV4.js";
function isPromise(value) {
  return typeof value == "object" && value !== null && typeof value.then == "function";
}
function observableToPromise(observable) {
  return new Promise((resolve, reject) => {
    const subscription = observable.subscribe({
      next(v) {
        resolve(v), subscription.unsubscribe();
      },
      error: reject,
      complete() {
        reject(new Error("no value resolved"));
      }
    });
  });
}
function isObservable(value) {
  return typeof value == "object" && value !== null && "subscribe" in value && typeof value.subscribe == "function";
}
function isAsyncIterable(input) {
  return typeof input == "object" && input !== null && // Some browsers still don't have Symbol.asyncIterator implemented (iOS Safari)
  // That means every custom AsyncIterable must be built using a AsyncGeneratorFunction (async function * () {})
  (input[Symbol.toStringTag] === "AsyncGenerator" || Symbol.asyncIterator in input);
}
async function asyncIterableToPromise(input) {
  var _a;
  const iteratorReturn = (_a = ("return" in input ? input : input[Symbol.asyncIterator]()).return) == null ? void 0 : _a.bind(input), result = await ("next" in input ? input : input[Symbol.asyncIterator]()).next.bind(input)();
  return iteratorReturn == null || iteratorReturn(), result.value;
}
async function fetcherReturnToPromise(fetcherResult) {
  const result = await fetcherResult;
  return isAsyncIterable(result) ? asyncIterableToPromise(result) : isObservable(result) ? observableToPromise(result) : result;
}
export {
  fetcherReturnToPromise,
  isAsyncIterable,
  isObservable,
  isPromise
};
