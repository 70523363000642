import "../chunk-LMOQIXV4.js";
import {
  getNamedType,
  isLeafType,
  Kind,
  parse,
  print,
  TypeInfo,
  visit
} from "graphql";
function fillLeafs(schema, docString, getDefaultFieldNames) {
  const insertions = [];
  if (!schema || !docString)
    return { insertions, result: docString };
  let ast;
  try {
    ast = parse(docString);
  } catch (e) {
    return { insertions, result: docString };
  }
  const fieldNameFn = getDefaultFieldNames || defaultGetDefaultFieldNames, typeInfo = new TypeInfo(schema);
  return visit(ast, {
    leave(node) {
      typeInfo.leave(node);
    },
    enter(node) {
      if (typeInfo.enter(node), node.kind === "Field" && !node.selectionSet) {
        const fieldType = typeInfo.getType(), selectionSet = buildSelectionSet(
          isFieldType(fieldType),
          fieldNameFn
        );
        if (selectionSet && node.loc) {
          const indent = getIndentation(docString, node.loc.start);
          insertions.push({
            index: node.loc.end,
            string: " " + print(selectionSet).replaceAll(`
`, `
` + indent)
          });
        }
      }
    }
  }), {
    insertions,
    result: withInsertions(docString, insertions)
  };
}
function defaultGetDefaultFieldNames(type) {
  if (!("getFields" in type))
    return [];
  const fields = type.getFields();
  if (fields.id)
    return ["id"];
  if (fields.edges)
    return ["edges"];
  if (fields.node)
    return ["node"];
  const leafFieldNames = [];
  for (const fieldName of Object.keys(fields))
    isLeafType(fields[fieldName].type) && leafFieldNames.push(fieldName);
  return leafFieldNames;
}
function buildSelectionSet(type, getDefaultFieldNames) {
  const namedType = getNamedType(type);
  if (!type || isLeafType(type))
    return;
  const fieldNames = getDefaultFieldNames(namedType);
  if (!(!Array.isArray(fieldNames) || fieldNames.length === 0 || !("getFields" in namedType)))
    return {
      kind: Kind.SELECTION_SET,
      selections: fieldNames.map((fieldName) => {
        const fieldDef = namedType.getFields()[fieldName], fieldType = fieldDef ? fieldDef.type : null;
        return {
          kind: Kind.FIELD,
          name: {
            kind: Kind.NAME,
            value: fieldName
          },
          // we can use as here, because we already know that fieldType
          // comes from an origin parameter
          selectionSet: buildSelectionSet(fieldType, getDefaultFieldNames)
        };
      })
    };
}
function withInsertions(initial, insertions) {
  if (insertions.length === 0)
    return initial;
  let edited = "", prevIndex = 0;
  for (const { index, string } of insertions)
    edited += initial.slice(prevIndex, index) + string, prevIndex = index;
  return edited += initial.slice(prevIndex), edited;
}
function getIndentation(str, index) {
  let indentStart = index, indentEnd = index;
  for (; indentStart; ) {
    const c = str.charCodeAt(indentStart - 1);
    if (c === 10 || c === 13 || c === 8232 || c === 8233)
      break;
    indentStart--, c !== 9 && c !== 11 && c !== 12 && c !== 32 && c !== 160 && (indentEnd = indentStart);
  }
  return str.slice(indentStart, indentEnd);
}
function isFieldType(fieldType) {
  if (fieldType)
    return fieldType;
}
export {
  fillLeafs
};
