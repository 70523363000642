import {
  __asyncGenerator,
  __await,
  __forAwait,
  __spreadProps,
  __spreadValues
} from "../chunk-LMOQIXV4.js";
import { visit } from "graphql";
import { meros } from "meros";
import {
  isAsyncIterable,
  makeAsyncIterableIteratorFromSink
} from "@n1ru4l/push-pull-async-iterable-iterator";
const errorHasCode = (err) => typeof err == "object" && err !== null && "code" in err, isSubscriptionWithName = (document, name) => {
  let isSubscription = !1;
  return visit(document, {
    OperationDefinition(node) {
      var _a;
      name === ((_a = node.name) == null ? void 0 : _a.value) && node.operation === "subscription" && (isSubscription = !0);
    }
  }), isSubscription;
}, createSimpleFetcher = (options, httpFetch) => async (graphQLParams, fetcherOpts) => (await httpFetch(options.url, {
  method: "POST",
  body: JSON.stringify(graphQLParams),
  headers: __spreadValues(__spreadValues({
    "content-type": "application/json"
  }, options.headers), fetcherOpts == null ? void 0 : fetcherOpts.headers)
})).json();
async function createWebsocketsFetcherFromUrl(url, connectionParams) {
  let wsClient;
  try {
    const { createClient } = await import("graphql-ws");
    return wsClient = createClient({ url, connectionParams }), createWebsocketsFetcherFromClient(wsClient);
  } catch (err) {
    if (errorHasCode(err) && err.code === "MODULE_NOT_FOUND")
      throw new Error(
        "You need to install the 'graphql-ws' package to use websockets when passing a 'subscriptionUrl'"
      );
    console.error(`Error creating websocket client for ${url}`, err);
  }
}
const createWebsocketsFetcherFromClient = (wsClient) => (graphQLParams) => makeAsyncIterableIteratorFromSink(
  (sink) => wsClient.subscribe(graphQLParams, __spreadProps(__spreadValues({}, sink), {
    error(err) {
      err instanceof CloseEvent ? sink.error(
        new Error(
          `Socket closed with event ${err.code} ${err.reason || ""}`.trim()
        )
      ) : sink.error(err);
    }
  }))
), createLegacyWebsocketsFetcher = (legacyWsClient) => (graphQLParams) => {
  const observable = legacyWsClient.request(graphQLParams);
  return makeAsyncIterableIteratorFromSink(
    // @ts-ignore
    (sink) => observable.subscribe(sink).unsubscribe
  );
}, createMultipartFetcher = (options, httpFetch) => function(graphQLParams, fetcherOpts) {
  return __asyncGenerator(this, null, function* () {
    const response = yield new __await(httpFetch(options.url, {
      method: "POST",
      body: JSON.stringify(graphQLParams),
      headers: __spreadValues(__spreadValues({
        "content-type": "application/json",
        accept: "application/json, multipart/mixed"
      }, options.headers), fetcherOpts == null ? void 0 : fetcherOpts.headers)
    }).then(
      (r) => meros(r, {
        multiple: !0
      })
    ));
    if (!isAsyncIterable(response))
      return yield response.json();
    try {
      for (var iter = __forAwait(response), more, temp, error; more = !(temp = yield new __await(iter.next())).done; more = !1) {
        const chunk = temp.value;
        if (chunk.some((part) => !part.json)) {
          const message = chunk.map(
            (part) => `Headers::
${part.headers}

Body::
${part.body}`
          );
          throw new Error(
            `Expected multipart chunks to be of json type. got:
${message}`
          );
        }
        yield chunk.map((part) => part.body);
      }
    } catch (temp) {
      error = [temp];
    } finally {
      try {
        more && (temp = iter.return) && (yield new __await(temp.call(iter)));
      } finally {
        if (error)
          throw error[0];
      }
    }
  });
};
async function getWsFetcher(options, fetcherOpts) {
  if (options.wsClient)
    return createWebsocketsFetcherFromClient(options.wsClient);
  if (options.subscriptionUrl)
    return createWebsocketsFetcherFromUrl(options.subscriptionUrl, __spreadValues(__spreadValues({}, options.wsConnectionParams), fetcherOpts == null ? void 0 : fetcherOpts.headers));
  const legacyWebsocketsClient = options.legacyClient || options.legacyWsClient;
  if (legacyWebsocketsClient)
    return createLegacyWebsocketsFetcher(legacyWebsocketsClient);
}
export {
  createLegacyWebsocketsFetcher,
  createMultipartFetcher,
  createSimpleFetcher,
  createWebsocketsFetcherFromClient,
  createWebsocketsFetcherFromUrl,
  getWsFetcher,
  isSubscriptionWithName
};
