import "../chunk-LMOQIXV4.js";
function getSelectedOperationName(prevOperations, prevSelectedOperationName, operations) {
  if (!operations || operations.length < 1)
    return;
  const names = operations.map((op) => {
    var _a;
    return (_a = op.name) == null ? void 0 : _a.value;
  });
  if (prevSelectedOperationName && names.includes(prevSelectedOperationName))
    return prevSelectedOperationName;
  if (prevSelectedOperationName && prevOperations) {
    const prevIndex = prevOperations.map((op) => {
      var _a;
      return (_a = op.name) == null ? void 0 : _a.value;
    }).indexOf(prevSelectedOperationName);
    if (prevIndex !== -1 && prevIndex < names.length)
      return names[prevIndex];
  }
  return names[0];
}
export {
  getSelectedOperationName
};
