import "../chunk-LMOQIXV4.js";
function isQuotaError(storage, e) {
  return e instanceof DOMException && // everything except Firefox
  (e.code === 22 || // Firefox
  e.code === 1014 || // test name field too, because code might not be present
  // everything except Firefox
  e.name === "QuotaExceededError" || // Firefox
  e.name === "NS_ERROR_DOM_QUOTA_REACHED") && // acknowledge QuotaExceededError only if there's something already stored
  storage.length !== 0;
}
class StorageAPI {
  constructor(storage) {
    storage ? this.storage = storage : storage === null ? this.storage = null : typeof window == "undefined" ? this.storage = null : this.storage = {
      getItem: localStorage.getItem.bind(localStorage),
      setItem: localStorage.setItem.bind(localStorage),
      removeItem: localStorage.removeItem.bind(localStorage),
      get length() {
        let keys = 0;
        for (const key in localStorage)
          key.indexOf(`${STORAGE_NAMESPACE}:`) === 0 && (keys += 1);
        return keys;
      },
      clear() {
        for (const key in localStorage)
          key.indexOf(`${STORAGE_NAMESPACE}:`) === 0 && localStorage.removeItem(key);
      }
    };
  }
  get(name) {
    if (!this.storage)
      return null;
    const key = `${STORAGE_NAMESPACE}:${name}`, value = this.storage.getItem(key);
    return value === "null" || value === "undefined" ? (this.storage.removeItem(key), null) : value || null;
  }
  set(name, value) {
    let quotaError = !1, error = null;
    if (this.storage) {
      const key = `${STORAGE_NAMESPACE}:${name}`;
      if (value)
        try {
          this.storage.setItem(key, value);
        } catch (e) {
          error = e instanceof Error ? e : new Error(`${e}`), quotaError = isQuotaError(this.storage, e);
        }
      else
        this.storage.removeItem(key);
    }
    return { isQuotaError: quotaError, error };
  }
  clear() {
    this.storage && this.storage.clear();
  }
}
const STORAGE_NAMESPACE = "graphiql";
export {
  StorageAPI
};
