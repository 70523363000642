import "../chunk-LMOQIXV4.js";
function createLocalStorage({
  namespace
}) {
  const storageKeyPrefix = `${namespace}:`, getStorageKey = (key) => `${storageKeyPrefix}${key}`;
  return {
    setItem: (key, value) => localStorage.setItem(getStorageKey(key), value),
    getItem: (key) => localStorage.getItem(getStorageKey(key)),
    removeItem: (key) => localStorage.removeItem(getStorageKey(key)),
    get length() {
      let keys = 0;
      for (const key in localStorage)
        key.indexOf(storageKeyPrefix) === 0 && (keys += 1);
      return keys;
    },
    clear() {
      for (const key in localStorage)
        key.indexOf(storageKeyPrefix) === 0 && localStorage.removeItem(key);
    }
  };
}
export {
  createLocalStorage
};
