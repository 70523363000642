import "../chunk-LMOQIXV4.js";
class QueryStore {
  constructor(key, storage, maxSize = null) {
    this.key = key;
    this.storage = storage;
    this.maxSize = maxSize;
    this.items = this.fetchAll();
  }
  get length() {
    return this.items.length;
  }
  contains(item) {
    return this.items.some(
      (x) => x.query === item.query && x.variables === item.variables && x.headers === item.headers && x.operationName === item.operationName
    );
  }
  edit(item, index) {
    if (typeof index == "number" && this.items[index]) {
      const found = this.items[index];
      if (found.query === item.query && found.variables === item.variables && found.headers === item.headers && found.operationName === item.operationName) {
        this.items.splice(index, 1, item), this.save();
        return;
      }
    }
    const itemIndex = this.items.findIndex(
      (x) => x.query === item.query && x.variables === item.variables && x.headers === item.headers && x.operationName === item.operationName
    );
    itemIndex !== -1 && (this.items.splice(itemIndex, 1, item), this.save());
  }
  delete(item) {
    const itemIndex = this.items.findIndex(
      (x) => x.query === item.query && x.variables === item.variables && x.headers === item.headers && x.operationName === item.operationName
    );
    itemIndex !== -1 && (this.items.splice(itemIndex, 1), this.save());
  }
  fetchRecent() {
    return this.items.at(-1);
  }
  fetchAll() {
    const raw = this.storage.get(this.key);
    return raw ? JSON.parse(raw)[this.key] : [];
  }
  push(item) {
    const items = [...this.items, item];
    this.maxSize && items.length > this.maxSize && items.shift();
    for (let attempts = 0; attempts < 5; attempts++) {
      const response = this.storage.set(
        this.key,
        JSON.stringify({ [this.key]: items })
      );
      if (!(response != null && response.error))
        this.items = items;
      else if (response.isQuotaError && this.maxSize)
        items.shift();
      else
        return;
    }
  }
  save() {
    this.storage.set(this.key, JSON.stringify({ [this.key]: this.items }));
  }
}
export {
  QueryStore
};
